<template>
  <component :is="brandData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="brandData === undefined">
      <h4 class="alert-heading">Erreur de récupération des données de marques</h4>
      <div class="alert-body">
        Pas de Marque trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-item-brands-list' }">
          la liste des marques
        </b-link>
        pour d'autres marques.
      </div>
    </b-alert>

    <b-tabs v-if="brandData" pills>
      <!-- Tab: Général -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Création Marque</span>
        </template>
        <handle-item-brand-tab-general
          class="mt-2 pt-75"
          :brand-data.sync="brandData"
          @data-handled="$router.push({ name: 'apps-item-brands-list' })"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import HandleItemBrandTabGeneral from "./HandleItemBrandTabGeneral.vue";
import useBrandsList from "../item-brands-list/useItemBrandsList";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "HandleItemBrand",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    HandleItemBrandTabGeneral,
  },
  setup() {
    const blankBrandData = {
      name: "",
      description: "",
    };

    const brandData = ref(JSON.parse(JSON.stringify(blankBrandData)));

    const fetchBrand = () => {
      store
        .dispatch("itemBrand/fetchBrand", { id: router.currentRoute.params.id })
        .then((brand) => {
          brandData.value = brand;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            brandData.value = undefined;
          }
        });
    };

    onMounted(() => {
      if (router.currentRoute.params.id) {
        fetchBrand();
      }
    });

    return {
      brandData,
    };
  },
};
</script>

<style></style>
