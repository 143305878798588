import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useBrandsList() {
  // Use toast
  const toast = useToast();
  const { route, router } = useRouter();

  const refBrandsListTable = ref(null);

  // filter options
  const brandsStatusOptions = [
    {label: "Active", key: true},
    {label: "Inactive", key: false},
  ];

  // Table Handlers
  const tableColumns = [
    { label: "Nº", key: "index", sortable: true },
    { label: "libellé", key: "name", sortable: true },
    { label: "description", key: "description" },
    { label: "Statut", key: "is_active", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("created_at");
  const isSortDirDesc = ref(true);
  const brandStatusFilter = ref(null);


  const brandsList = computed(() => {
    return store.getters["itemBrand/all"];
  });

  const totalBrands = computed(() => {
    const meta = store.getters["itemBrand/meta"];
    return meta.total ?? 0;
  });

  const dataMeta = computed(() => {
    const localItemsCount = refBrandsListTable.value
      ? refBrandsListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBrands.value,
    };
  });

  const refetchData = () => {
    refBrandsListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, brandStatusFilter],
    () => {
      refetchData();
    }
  );

  const fetchBrandsList = (ctx, callback) => {
    store
      .dispatch("itemBrand/fetchBrandsList", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        is_active: brandStatusFilter.value,
      })
      .then(() => {
        callback(brandsList.value);
      })
  };

  const deleteBrand = async (brandID) => {
    const { data, status } = await store.dispatch("itemBrand/destroyBrand", {
      id: brandID,
    });
    if (status != 200) {
      toast({
        component: ToastificationContent,
        props: {
          title: data.error,
          icon: "AlertTriangleIcon",
          variant: "error",
        },
      });
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: "La marque a été supprimée avec succès.",
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      refetchData();
    }
  };


  return {
    fetchBrandsList,
    tableColumns,
    perPage,
    currentPage,
    totalBrands,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBrandsListTable,
    refetchData,
    deleteBrand,

    brandStatusFilter,
    brandsStatusOptions,
  };
}
